.about {
  display: flex;
  flex-flow: row wrap;
  background: url(../images/about_bg.jpg) 50%/cover no-repeat fixed;

  & &__infomation,
  & &__feature {
    width: 50%;
    @media (--phone){
      width: 100%;
    }
  }

  & &__infomation {
    padding: 3em;
    background-color: color(white a(.7));
  }

  & &__feature &__feature-wrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 50%;
    padding: 3em;

    &:first-child {
      background-color: color(white a(.5));
    }
    &:last-child {
      background-color: color(var(--gray) a(.8));
    }
  }
}
