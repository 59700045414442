.slogan {
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--zIndex__bottom);
    background: url(../images/slogan_adorn.png) 50%/cover no-repeat,
                linear-gradient(white -50%, transparent);
  }

  > .vc_row {
    z-index: var(--zIndex__middle);
  }
}
