.images-wrap {
  &__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 1000px;
    transform: translate(-50%, -50%);
    background-color: white;
    text-align: center;
  }
}
