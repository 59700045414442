@define-mixin formInput {
  padding-left: 1em;
  height: 2.6em;
  margin-top: 1em;
  background-color: transparent;
  border-color: var(--white);
  font: 400 1rem/1.2 robot, sans-serif;
  color: var(--white);

  &::placeholder {
    color: var(--white);
    opacity: 1;
  }
  &::-ms-placeholder {
    color: var(--white);
  }
  &:-ms-placeholder {
    color: var(--white);
  }
}
.wpcf7 {
  &-form {
    padding-top: 1em;

    input {
      &[type="tel"],
      &[type="email"],
      &[type="text"] {
        @mixin formInput;
      }

      &[type="tel"],
      &[type="email"],
      &[name="your-name"] {
        width: 100%;
        @media (--desktop){
          width: calc(( 100% - 2em ) / 3);
        }
      }

      &[type="tel"],
      &[type="email"] {
        @media (--desktop){
          margin-left: 1em;
        }
      }

      &[name="your-message"] {
        width: 100%;
      }

      &[type="submit"],
      &[type="reset"] {
        padding: .3em 1em;
        border-radius: 0;
        font: 600 1rem/1.2 robot, sans-serif;
      }

      &[type="reset"] {
        background-color: var(--white);
        border-color: var(--white);
        color: var(--orange);

        &:hover {
          background-color: var(--orange);
          border-color: var(--orange);
          color: var(--white);
        }
      }
    }
  }
}
