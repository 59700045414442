.btn {
  &&_themes_wheel {
    &:before {
      content: '';
      display: inline-block;
      width: 1.3em;
      height: 1.3em;
      margin-right: .5em;
      mask: url(../images/icon_wheel@3x.png) 50%/contain; 
      background-color: var(--white);
      vertical-align: middle;
    }

    &_orange {
      &:before {
        background-color: var(--orange);
      }
    }
  }
}
