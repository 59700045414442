.colors {
  &_white {
    color: var(--white);
  }
  &_orange {
    color: var(--orange);
  }
  &_black {
    color: var(--black);
  }
}
