.contact-information {
  @media (--phone) {
    text-align: center;

    br {
      display: none;
    }

    .text {
      font-size: 12px;
    }

    a,
    b {
      margin-right: 6px;
    }
  }
}
