.images-wrap {
  &__arrow {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    border-top: 1px solid white;
    cursor: pointer;
  }
}

.arrow {
  &_left {
    left: -20%;
    border-left: 1px solid white;
    transform: translateY(-50%) rotate(-45deg);
  }

  &_right {
    right: -20%;
    border-right: 1px solid white;
    transform: translateY(-50%) rotate(45deg);
  }
}
