.woocommerce {
  .products {
    margin-bottom: 0 !important;
  }

  .product-category {
    padding: 1em 0 !important;
    transition: .4s;

    &:hover {
      background-color: color( var( --gray ) l(+30%) );
      box-shadow: 3px 3px 18px var( --gray );
      transition: background-color .4s, box-shadow .4s .2s;
    }

    .count {
      display: none;
    }

    .woocommerce-loop-category__title {
      font: 700 1.2rem/1.2 robot, sans-serif !important;
      text-transform: capitalize;
    }

    img {
      max-width: 16em !important;
      height: 18em !important;
      margin: 0 auto !important;
      object-fit: contain;
    }

    @media (--phone) {
      &:last-child:not(:nth-child(2n)) {
        float: none !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }

  &.post-type-archive,
  &.tax-product_cat {
    #primary {
      @media (--desktop){
        float: right;
      }
      @media (--phone){
        order: 2;
      }
    }

    #secondary {
      @media (--desktop){
        float: left;
      }
    }

    .page-wrap {
      padding: 3em 0 !important;
      @media (--phone){
        > .content-wrapper > .row {
          display: flex;
          flex-flow: column nowrap;
        }
      }
    }

    .woocommerce-breadcrumb,
    .woocommerce-result-count,
    .woocommerce-ordering,
    .button.product_type_simple {
      display: none;
    }

    .archive-title {
      text-transform: uppercase;
      font: 700 2rem/1.2 robot, sans-serif;
      color: var(--orange);
    }

    .products {
      padding-top: 2em;
    }

    .product {
      @media (--phone) {
        &:last-child:not(:nth-child(2n)) {
          float: none;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &.featured {
        .woocommerce-LoopProduct-link {
          display: block;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 1em;
            left: 1em;
            z-index: var(--zIndex__bottom);
            width: 3.5em;
            height: 3em;
            background: url(../images/icon_hot@3x.png) 50%/contain no-repeat;
          }
        }
      }

      &:hover {
        .woocommerce-LoopProduct-link {
          &:before {
            mix-blend-mode: color-dodge;
          }
        }
        .product__img {
          background-color: color( var(--gray) l(+30%) );
        }
      }
    }

    .product__img {
      position: relative;
      padding-top: 100%;
      border: 1px solid color( var(--gray) l(+30%) );
      transition: background-color .4s;
    }

    .attachment-woocommerce_thumbnail {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100% !important;
      max-height: 100%;
      margin-bottom: 0 !important;
      object-fit: contain;
    }

    .woocommerce-loop-product__title {
      padding: 1em 0 0 !important;
      font: 500 1rem/1.2 robot, sans-serif !important;
      text-transform: capitalize;
      color: var(--black);
    }

    .product__meta {
      color: var(--black) !important;
      font: 500 1rem/1.6 robot, sans-serif;
    }

    .woocommerce-pagination {
      @media (--phone) {
        margin-top: 2em;
      }
    }
  }
}
