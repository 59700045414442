.enquiry {
  position: relative;
  background: url(../images/enquiry_bg@1x.jpg) 50%/cover no-repeat fixed;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color(black a(.8));
  }
}
