.footer-widgets {
  padding: 2em 0;
  @media (--phone){
    text-align: center;
  }

  address {
    margin-bottom: 0;
  }

  .widget {
    &:last-child {
      margin-bottom: 2em;
      @media (--desktop){
        margin-bottom: 0;
      }
    }
  }

  .widget-title {
    text-transform: uppercase;
    font: 600 1rem/1.2 robot, sans-serif;
    color: var(--orange);
  }

  .textwidget {
    font: 400 1rem/1.2 robot, sans-serif;

    b {
      font-weight: 600;
    }
  }

  .menu {
    font: 500 1rem/1.2 robot, sans-serif;

    &#menu-nav-1,
    &#menu-rim {
      a {
        text-transform: capitalize;
      }
    }
  }

  #media_image-2 {
    margin-bottom: 0;
    text-align: center;
  }

  #custom_html-2 {
    padding-top: 0;

    .site-company {
      margin-top: 1em;
      font: 600 1rem/1.2 robot, sans-serif;
      color: var(--black);
      text-align: center;
    }
  }

  #custom_html-3 {
    @media (--phone) {
      .textwidget {
        font-size: 11px;
      }

      span[style] {
        display: none !important;
      }

      br {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(8),
        &:nth-child(10) {
          display: none;
        }
      }
    }
  }
}
