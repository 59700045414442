.wpb_single_image {
  display: flex;
  flex-flow: column nowrap;

  .wpb_heading {
    order: 2;
    margin-top: 1em;
    text-transform: uppercase;
    font: 600 1rem/1.2 robot, sans-serif;
    color: var(--gray);
  }
}
