@define-mixin bdTop {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 30%;
    height: 1px;
    background-color: color( var(--gray) l(+30%) );
  }
}
.widget_product_categories {
  .product-categories {
    text-transform: uppercase;

    .cat-item {
      padding: 0;

      > a {
        display: block;
        padding: .5em 0;
        font: 500 1rem/1.2 Roboto, sans-serif;
        color: var(--black) !important;
        @mixin bdTop;
      }

      &.cat-parent {
        > a::after {
          content: '\002b';
          margin-left: .5em;
        }

        &.current-cat,
        &.current-cat-parent {
          > a::after {
            content: '\002d';
          }
        }
      }

      + .cat-item {
        &:not(.cat-parent) {
          > a {
            @mixin bdTop;
          }
        }
      }

      &.current-cat,
      &.current-cat-parent {
        > a {
          color: var(--orange) !important;
        }

        > .children {
          display: block;
        }
      }
    }

    .children {
      display: none;
    }
  }
}
