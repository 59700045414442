.mainnav {
  .menu-item {
    text-transform: uppercase;
  }
}
#mainnav {
  .menu {
    margin-top: 1.2em;
    text-align: center;

    &-item {
      padding: 0 2em;

      &:nth-child(3) {
        margin-left: 4em;
      }

      &.current_page_item,
      &.current-menu-item {
        > a {
          font-weight: 700;
          color: var(--orange);
        }
      }
    }
  }
}
