.images-wrap {
  & &__close {
    position: absolute;
    top: .5em;
    right: .5em;
    padding: 0;
    border: none;
    color: black;
    background-color: transparent;
    font-size: 2rem;
  }
}
