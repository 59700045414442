.header-clone {
  display: none;
}
.site-header {
  position: absolute !important;
  background-color: transparent;
  @media (--phone){
    padding: 1em 0;
  }

  &.fixed {
    position: absolute !important;
    background-color: transparent;
  }
}
