.site-logo {
  max-height: var(--logo__maxW);
  margin-bottom: 0;

  @media (--desktop){
    position: absolute;
    top: 0;
    left: 50%;
    z-index: var(--zIndex__bottom);
    margin-bottom: 0;
    max-height: var(--logo__maxW);
    transform: translateX(calc( -50% - 25px));
  }
}
